.keypad-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.keypad-row > button {
  display: flex;
  padding: none;
  margin: 10px;
  height: 90px;
  width: 90px;
  border-radius: 45px;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.3); 
  transition: 0.1s ease-out;
  -webkit-transition: 0.1s ease-out;
}

.keypad-row > button > h1 {
  font-size: 2rem;
}

.keypad-row > button:active {
  transform: scale(0.85);
}
