html, body {
  margin: 0;
  padding: 0;
  background-color: #f2f2f2;
}

a {
  text-decoration: none;
  color: #333;
}

td {
  overflow: visible !important;
}

.centered-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  z-index: 5;
  top: 0;
}